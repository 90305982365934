
/**
 * Enum representing the state of a permission.
 * @enum {string}
 */
export enum PermissionState {
  /** Permission has been granted */
  GRANTED = 'granted',

  /** Permission has been denied */
  DENIED = 'denied',

  /** Permission prompt indicates that the user has not yet granted or denied the permission, and the application can prompt the user to make a decision. */
  PROMPT = 'prompt',
}

export enum PermissionType {
  AUDIO = "microphone",
  CAMERA = "camera",
  SCREEN = "screen",
}

export type BlockPermissionSettings = {
  [key in PermissionType]: boolean;
};

export interface IPermissions {
  cameraPermissionState: PermissionState;
  microphonePermissionState: PermissionState;
  screenPermissionState: PermissionState;
}