import { useState, useEffect, MutableRefObject } from 'react';


function useDimensions(props: { ref: MutableRefObject<HTMLElement | null>, imageLoaded?: boolean } | { id: string, imageLoaded?: boolean }) {
    const { ref, id, imageLoaded } = 'ref' in props ? { ref: props.ref, id: undefined, imageLoaded: props.imageLoaded } : { ref: undefined, id: props.id, imageLoaded: props.imageLoaded };
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        function updateSize() {
            if (ref && ref.current) {
                setDimensions({
                    width: ref.current.offsetWidth,
                    height: ref.current.offsetHeight
                });
            } else if (id) {
                const element = document.getElementById(id);
                if (element) {
                    setDimensions({
                        width: element.offsetWidth,
                        height: element.offsetHeight
                    });
                }
            }
        }

        window.addEventListener('resize', updateSize);
        updateSize(); // Initial call

        return () => window.removeEventListener('resize', updateSize);
    }, [ref, id, imageLoaded]);

    return dimensions;
}

export default useDimensions;