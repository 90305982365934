import { isChrome, isFirefox, isSafari } from './userAgent';

export default function getMimeType(hasCameraAccess:boolean): string {

  if (isChrome()) {
    return hasCameraAccess ? 'video/webm' : 'audio/webm';
  } else if (isFirefox()) {
    return hasCameraAccess ? 'video/webm' : 'audio/webm';
  } else if (isSafari()) {
    return hasCameraAccess ? 'video/mp4' : 'audio/mp4';
  } else {
    return hasCameraAccess ? 'video/mp4' : 'audio/mp4';
  }
}