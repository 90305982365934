import _ from 'lodash';
import { Block, BlockType } from '../../../../../models/Test';

export function responseValidator(response: any, blockData: Block) {
	const errors = [];

	if (Object.keys(response).length === 0) {
		errors.push("Пожалуйста, введите ответ");
		return { isValid: false, errors: errors };
	}

	if (blockData.type === BlockType.firstclick) {
		if (!_.has(response, "clickData") || response.clickData === null) {
			errors.push("Пожалуйста, выберите точку на изображении");
		}
	}

	if (blockData.type === BlockType.openquestion) {
		response.textValue === "" &&
			errors.push("Пожалуйста, введите текст ответа");
	}

	if (blockData.type === BlockType.choice) {
		if (response.selectedOptions.length === 0) {
			errors.push("Пожалуйста, выберите хотя бы одну опцию");
		}
		const otherOption = response.selectedOptions.find((option: any) => option.id === "other");
		if (otherOption && otherOption.value === "") {
			errors.push("Пожалуйста, введите текст ответа для варианта Другое");
		}
	}

	if (blockData.type === BlockType.preference) {
		response?.selectedOptions?.length === 0 &&
			errors.push("Пожалуйста, выберите хотя бы одну опцию");
	}

	if (blockData.type === BlockType.cardsort) {
		if (response.sorting.find((column: any) => column.id === "unsorted").cards.length > 0 && !blockData.doNotRequireToSortAll) {
			errors.push("Please, sort all of the cards");
		}
		if (response.sorting.filter((column:any) => column.id !== "unsorted").some((column: any) => {console.log(column.value); return (column.value === "" || column.value === undefined)})) {
			errors.push("Please, name all of the categories");
		}
	}

	if (blockData.type === BlockType.matrix) {
		Object.keys(response.selectedOptions).forEach((row) => {
			if (response.selectedOptions[row].length === 0) {
				errors.push(`Please, select at least one option for ${row}`);
			}
		});
	}

	console.log(errors);

	return { isValid: errors.length === 0, errors: errors };
}