import { BlockType } from './BlockType';
import { PreferenceBlock } from './IBlock';
import { Block } from './IBlock';

export const BLOCKS_WITH_IMAGES = [BlockType.openquestion, BlockType.context, BlockType.choice, BlockType.fiveseconds, BlockType.scale];
export const BLOCKS_OPTIONAL = [BlockType.openquestion, BlockType.preference, BlockType.choice, BlockType.scale, BlockType.matrix];
export const BLOCKS_SUPPORTED_BY_CONDITIONS = [BlockType.openquestion, BlockType.preference, BlockType.choice, BlockType.scale, BlockType.figma];

export function canHaveImage(type: BlockType) {
	return BLOCKS_WITH_IMAGES.includes(type);
}

export function canBeOptional(type: BlockType) {
	return BLOCKS_OPTIONAL.includes(type);
}

export function canShowBlockInLogic(block: Block) {
	if (block.type === BlockType.preference && (block as PreferenceBlock).comparisonType === "pairwise") {
		return false;
	}
  return BLOCKS_SUPPORTED_BY_CONDITIONS.includes(block.type);
}
