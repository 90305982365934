import React, { useRef } from 'react';

import usePermissions from '../hooks/usePermissions';

import { PermissionType, PermissionState } from '../models/types';
import BlockPermission from '../models/BlockPermissions';
import Permissions from '../models/Permissions';

interface IPermissionContext {
  permissions: Permissions; // доступы, которые есть на уровне браузера
  screenStreamRef?: React.MutableRefObject<MediaStream | null>;
  blockPermissionSettings: BlockPermission; // доступы, которые юзер дал для прохождения блока задания
  isInitialPermissionsLoaded: boolean;
  requestPermission: (permissionId: PermissionType | [PermissionType.AUDIO, PermissionType.CAMERA]) => void;
  setBlockPermissionSettings: (settings: BlockPermission | ((prev: BlockPermission) => BlockPermission)) => void;
}

const defaultPermissions: IPermissionContext = {
  permissions: new Permissions(),
  blockPermissionSettings: new BlockPermission(),
  isInitialPermissionsLoaded: false,
  setBlockPermissionSettings: () => {},
  requestPermission: async () => PermissionState.PROMPT,
};

export const PermissionsContext = React.createContext<IPermissionContext>(defaultPermissions);

interface IPermissionsProviderProps {
  children: React.ReactNode;
  requiredPermissions: BlockPermission; //доступы, которые нужно запросить для блока
}

export const PermissionsProvider: React.FC<IPermissionsProviderProps> = ({
  children,
  requiredPermissions,
}) => {
  const screenStreamRef = useRef<MediaStream | null>(null);
  const { permissions,
    blockPermissionSettings,
    setBlockPermissionSettings,
    isInitialPermissionsLoaded,
    requestPermission } = usePermissions(requiredPermissions, screenStreamRef);


  return <PermissionsContext.Provider value={{
    permissions,
    requestPermission,
    screenStreamRef,
    blockPermissionSettings,
    setBlockPermissionSettings,
    isInitialPermissionsLoaded,
  }}>{children}</PermissionsContext.Provider>;
};

export const usePermissionsContext = () => React.useContext(PermissionsContext);
