import { ConditionType, LogicCondition, LogicStatement } from "../models/Logic";
import { Block, BlockType, IPreferenceOptionReply } from "../models/Test";
import { getBlockLogic } from "../utils/tests";
import { getScaleRepliesList } from "./Report/utils";

import i18n from "../i18n/config";

export interface ITestValidationError {
  blockId: string;
  text: string;
}

export function testValidator(testContentEntries: Block[]) {
  const errors: (ITestValidationError | string)[] = [];

  if (testContentEntries.length === 0) {
    errors.push(i18n.t("Add at least one block"));
    return { isValid: false, errors: errors };
  }
  testContentEntries.forEach((block) => {
    if (block.type === BlockType.openquestion) {
      if (!block.text) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Qustion text shouldn't be empty."),
        });
      }
    }
    if (block.type === BlockType.choice || block.type === BlockType.preference) {
      if (block.text.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, enter a question"),
        });
      }
      if (block.type === BlockType.choice) {
        block.replies.forEach((reply, i) => {
          if (!reply.replyValue || reply.replyValue.length < 1) {
            errors.push({
              blockId: block.blockId,
              text: `${i18n.t("Please, fill in the value of reply #")}${i + 1}`,
            });
          }
        });
        if (block.hasChoiceLimit && !block.choiceLimit) {
          errors.push({
            blockId: block.blockId,
            text: i18n.t("Please, set a limit for the number of choices"),
          });
        }
      }
      if (block.type === BlockType.preference && block.replies.some((r) => !r.image)) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("All replies should have an image"),
        });
      }
      if (block.type === BlockType.preference && block.comparisonType === "pairwise" && block.replies.length < 3) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, add use at least 3 options to create pairwise comparisons"),
        });
      }
    }
    if (block.type === BlockType.firstclick) {
      // Firstclick Validator
      if (block.text.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Instruction text shouldn't be empty"),
        });
      }
      if (block.image.length < 1) {
        errors.push({ blockId: block.blockId, text: i18n.t("Please, add an image") });
      }
    }
    if (block.type === BlockType.cardsort) {
      // Cardsort Validator
      if (!block.text || block.text.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Task text shouldn't be empty"),
        });
      }
      if (block.cards.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, add at least one card"),
        });
      }
      if (!block.withImages && block.cards.some((card) => !card.value || card.value.length < 1)) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, add a name for each card"),
        });
      }
      if (block.withImages && block.cards.some((card) => !card.image && !card.value)) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, add an image or a name for each card"),
        });
      }
      if (!block.isOpenSort && block.categories.length < 2) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, add at least 2 categories"),
        });
      }
      if (block.categories.length > 0 && block.categories.some((category) => !category.value || category.value.length < 1)) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, add a name for each category"),
        });
      }
    }
    if (block.type === BlockType.matrix) {
      // Matrix Validator

      if (block.text.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, enter a question"),
        });
      }

      if (block.rows.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, add at least one row"),
        });
      }
      if (block.columns.length < 2) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, add at least 2 columns"),
        });
      }

      block.columns.forEach((column) => {
        if (!column.value || column.value.length < 1) {
          errors.push({
            blockId: block.blockId,
            text: i18n.t("Column names shouldn't be empty"),
          });
          return;
        }
      });

      block.rows.forEach((row) => {
        if (!row.value || row.value.length < 1) {
          errors.push({
            blockId: block.blockId,
            text: i18n.t("Row names shouldn't be empty"),
          });
          return;
        }
      });
    }
    if (block.type === BlockType.context) {
      // Context Validator
      if (block.text.length < 1) {
        errors.push({ blockId: block.blockId, text: i18n.t("Please, add a text") });
      }
    }
    if (block.type === BlockType.fiveseconds) {
      // fiveseconds Validator
      if (block.image.length < 1) {
        errors.push({ blockId: block.blockId, text: i18n.t("Please, add an image") });
      }
      if (!block.text || block.text.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Instruction text shouldn't be empty"),
        });
      }
    }
    if (block.type === BlockType.figma) {
      // Figma Validator
      if (block.text.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, add an instruction"),
        });
      }
      if (!block.prototypeId && !block.fileId) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, paste a Figma prototype export code"),
        });
      }
      if (!block.goalNode || block.goalNode.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, set one or more Goal Screens"),
        });
      }
    }

    if (block.type === BlockType.scale) {
      if (!block.text || block.text.length < 1) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t("Please, enter a question"),
        });
      }
    }

    if (block.type === BlockType.ai) {
      // ai question validator
      if (block.text.length < 1) {
        errors.push({ blockId: block.blockId, text: i18n.t("Please, add a text") });
      }
      if (block.instruction.length < 1) {
        errors.push({ blockId: block.blockId, text: i18n.t("Please, add an instruction for AI") });
      }
    }

    // Logic validation
    const logic = getBlockLogic(block);

    if (logic) {
      if (logic.elseJumpTo === "") {
        errors.push({
          blockId: block.blockId,
          text: block.type === BlockType.context
            ? i18n.t("Always go to block shouldn't be empty")
            : i18n.t("If nothing matched, go to block shouldn't be empty"),
        });
      }
      // check conditions
      if (logic.statements.length > 0) {
        logic.statements.forEach((s) => validateStatement(s, errors, block, testContentEntries));
      }

      if (logic.visibility?.length) {
        logic.visibility.forEach((s) => validateStatement(s, errors, block, testContentEntries));
      }
    }
  });
  return { isValid: errors.length === 0, errors: errors };
}

function validateStatement(statement: LogicStatement, errors: (string | ITestValidationError)[], block: Block, allBlocks: Block[]) {
  if (statement.subconditions.length < 1) {
    errors.push({
      blockId: block.blockId,
      text: i18n.t("Please, add at least one condition"),
    });
  }

  if (!statement.visibilityType && statement.jumpTo === "") {
    errors.push({
      blockId: block.blockId,
      text: i18n.t("Go to block value shouldn't be empty"),
    });
  }

  // check subconditions
  if (statement.subconditions.length > 0) {
    statement.subconditions.forEach((s) => validateSubcondition(s, !!statement.visibilityType, errors, block, allBlocks));
  }
}

function validateSubcondition(subcondition: LogicCondition, isVisibility: boolean, errors: any[], block: Block, allBlocks: Block[]) {
  if (subcondition.condition !== ConditionType.isEmpty && subcondition.condition !== ConditionType.gaveUp && subcondition.value === "") {
    errors.push({
      blockId: block.blockId,
      text: i18n.t("Condition value shouldn't be empty"),
    });
  }
  if (!subcondition.condition) {
    errors.push({
      blockId: block.blockId,
      text: i18n.t("Condition expression shouldn't be empty"),
    });
  }
  if (isVisibility && !subcondition.valueSource) {
    errors.push({
      blockId: block.blockId,
      text: i18n.t("Please, select block in condition expression"),
    });
  }

  if (subcondition.valueSource) {
    const sourceBlock = allBlocks.find((b) => b.blockId === subcondition.valueSource);
    if (!sourceBlock) {
      errors.push({
        blockId: block.blockId,
        text: i18n.t(`Block specified in expression doesn't exist.`),
      });
      return;
    }

    if (sourceBlock.type == BlockType.choice || sourceBlock.type === BlockType.preference) {
      if (!sourceBlock.replies.some((r) => r.id === subcondition.value)) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t(`Reply value specified in expression doesn't exist.`),
        });
      }
    }
    if (sourceBlock.type === BlockType.scale) {
      const replies = getScaleRepliesList(sourceBlock);
      if (!replies.some((r) => r.value === subcondition.value)) {
        errors.push({
          blockId: block.blockId,
          text: i18n.t(`Reply value specified in expression doesn't exist.`),
        });
      }
    }
  }
}
