import React from "react";

import useIsMobile from "../../hooks/useIsMobile";
import { IconMic, IconCamera, IconMonitor, IconCancel, IconTick } from "../../../../../../icons";
import { usePermissionsContext } from "./context/PermissionsContext";
import { locale, interfaceText } from "../../../../../../helpers";
import Switcher from "../../../../../Switcher";
import { PermissionType, PermissionState } from "./models/types";

interface SettingsItem {
  id: PermissionType;
  name: string;
  icon: React.JSX.Element;
  iconDisabled: React.JSX.Element;
  permissionState: PermissionState;
  canBeGrantedAgain: boolean; // if browser allows to grant permission again and show alert
}

interface BlockSettingsProps {
  withCamera: boolean;
  withAudio: boolean;
  withVideo: boolean;
}

export default function BlockSettings(props: BlockSettingsProps) {
  const UserPermissions: { [key: string]: SettingsItem } = {
    [PermissionType.SCREEN]: {
      id: PermissionType.SCREEN,
      name: interfaceText.permissionsSettings[locale()].screen,
      icon: <IconMonitor stroke="#1F2226" width={20} height={20} />,
      iconDisabled: <IconMonitor stroke="#8E9399" width={20} height={20} />,
      permissionState: PermissionState.PROMPT,
      canBeGrantedAgain: true,
    },
    [PermissionType.CAMERA]: {
      id: PermissionType.CAMERA,
      name: interfaceText.permissionsSettings[locale()].camera,
      icon: <IconCamera stroke="#1F2226" width={20} height={20} />,
      iconDisabled: <IconCamera stroke="#8E9399" width={20} height={20} />,
      permissionState: PermissionState.PROMPT,
      canBeGrantedAgain: false,
    },
    [PermissionType.AUDIO]: {
      id: PermissionType.AUDIO,
      name: interfaceText.permissionsSettings[locale()].microphone,
      icon: <IconMic width={20} height={20} />,
      iconDisabled: <IconMic fill="#8E9399" width={20} height={20} />,
      permissionState: PermissionState.PROMPT,
      canBeGrantedAgain: false,
    },
  };

  const isMobile = useIsMobile();
  const { permissions, requestPermission, blockPermissionSettings, setBlockPermissionSettings } = usePermissionsContext();
  const userPermissions = getPermissions();

  function getPermissions(): SettingsItem[] {
    let userPermissions: SettingsItem[] = [];

    if (props.withVideo && !isMobile) {
      const userPermission = { ...UserPermissions[PermissionType.SCREEN], permissionState: permissions.screen };
      userPermissions.push(userPermission);
    }

    if (props.withAudio) {
      const userPermissionAudio = { ...UserPermissions[PermissionType.AUDIO], permissionState: permissions.microphone };
      userPermissions.push(userPermissionAudio);
    }

    if (props.withCamera) {
      const userPermission = { ...UserPermissions[PermissionType.CAMERA], permissionState: permissions.camera };
      userPermissions.push(userPermission);
    }

    return userPermissions;
  };

  const togglePermissionState = (permissionId: PermissionType) => {
    if (permissionId === PermissionType.SCREEN && blockPermissionSettings[permissionId] === false) {
      requestPermission(PermissionType.SCREEN);
    }

    setBlockPermissionSettings(prev => {
      const updated = prev.clone();
      updated[permissionId] = !prev[permissionId]; 
      return updated; 
    });
  };

  return (
    <div className="block-settings flex flex-col gap-4 w-full">
      <p className="text-gray-700 text-left w-full">{interfaceText.permissionsSettings[locale()].allowPermissionsInfo}</p>
      <div className="block-settings__permissions flex flex-col gap-3 border-1 border-gray-200 rounded-lg py-3 shadow-xxs">
        {userPermissions.map((permission, index) => {
          let showSettingsSwitcher = permission.permissionState === PermissionState.GRANTED;
          let showEnableButton = permission.permissionState === PermissionState.PROMPT || (permission.canBeGrantedAgain && permission.permissionState === PermissionState.DENIED);

          return(
          <React.Fragment key={permission.id}>
            <div className="block-settings__permission-item flex items-center justify-between px-3">
              <div className="block-settings__permission flex items-center gap-2">
                {showSettingsSwitcher && <Switcher handler={() => togglePermissionState(permission.id)} isOn={blockPermissionSettings[permission.id]} />}

                <div className="flex items-center gap-2">
                  {permission.permissionState === PermissionState.GRANTED ? permission.icon : permission.iconDisabled}
                  <p className={`block-settings__permission-name ${permission.permissionState === PermissionState.GRANTED ? "text-gray-800" : "text-gray-600"}`}>{permission.name}</p>
                </div>
              </div>

              {
                showEnableButton 
                  ? <button className="text-blue-500" onClick={() => requestPermission(permission.id)}>{interfaceText.permissionsSettings[locale()].enable}</button>
                  : permission.permissionState === PermissionState.DENIED
                    ? <div className="flex items-center gap-1">
                      <IconCancel fill="#F8511D" width={20} height={20} />
                      <p className="text-orange-600">{interfaceText.permissionsSettings[locale()].blocked}</p>
                    </div>
                    : <IconTick fill="#22C55E" width={20} height={20} />
              }
            </div>
            {index < userPermissions.length - 1 && (
              <div className="block-settings__divider border-b-1 border-gray-200"></div>
            )}
          </React.Fragment>
        )})}
      </div>
      {
        (
          (props.withAudio && permissions.microphone === PermissionState.DENIED) ||
          (props.withCamera && permissions.camera === PermissionState.DENIED)
        ) && (
          <p className="text-gray-700 text-left w-full">
            {interfaceText.permissionsSettings[locale()].blockedPermissionsInfo}
          </p>
        )
      }
    </div>
  );
}
