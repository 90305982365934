import { PermissionState } from "./types";

export default class Permissions {
  public camera: PermissionState = PermissionState.PROMPT;
  public microphone: PermissionState = PermissionState.PROMPT;
  public screen: PermissionState = PermissionState.PROMPT;

  public clone(): Permissions {
    const clone = new Permissions();
    clone.camera = this.camera;
    clone.microphone = this.microphone;
    clone.screen = this.screen;
    return clone;
  }
}
