export const FREE_TARIFF_CODE = 'free';
export const TEAM_TARIFF_CODE = 'team';
export const TEAM_TARIFF_ID = window.pwconfig.teams_tariff_id || 130;
export const CORPORATE_TARIFF_ID = 3;
export const ENTERPRISE_TARIFF_CODE = 'enterprise';

export const ROOT_FOLDER_ID = "root";

// Add constants at the top after imports
export const MIME_TYPES = {
	VIDEO_WEBM: 'video/webm',
	AUDIO_WEBM: 'audio/webm',
	/**
	 * Safari can't record video in webm
	 */
	VIDEO_MP4: 'video/mp4',
	/**
	 * Safari can't record audio in webm
	 */
	AUDIO_MP4: 'audio/mp4',
};