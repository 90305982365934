import { FirebaseOptions, initializeApp } from 'firebase/app';
import { FirebaseStorage, getStorage, ref, uploadBytes, uploadString } from 'firebase/storage';
import { IFileStorage } from './IFileStorage';

export class FirebaseFileStorage implements IFileStorage {

	private storage: FirebaseStorage;
	
	constructor(firebaseConfig: FirebaseOptions) {
		initializeApp(firebaseConfig);
		this.storage = getStorage();
	}

	public uploadBytes(file: Blob, fileName: string, metadata?: any): Promise<any> {
		return uploadBytes(ref(this.storage, fileName), file, metadata);
	}
	
	public uploadString(file: string, fileName: string, metadata?: any): Promise<any> {
		return uploadString(ref(this.storage, fileName), file, metadata);
	}

}