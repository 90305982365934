export function isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isChrome(): boolean {
    return /chrome|crios/i.test(navigator.userAgent) && !/edge|edgios/i.test(navigator.userAgent);
}

export function isFirefox(): boolean {
    return /firefox|fxios/i.test(navigator.userAgent);
}

export function isEdge(): boolean {
    return /edg|edge|edgios/i.test(navigator.userAgent);
}