import { AWSFileStorage } from "./AWSFileStorage";
import { FirebaseFileStorage } from "./FirebaseFileStorage";

export default function useFileStorage() {
  const fileStorage = window.pwconfig.storage === 'aws' || window.pwconfig.storage === 'aws_with_fallback' ?
    new AWSFileStorage() :
    new FirebaseFileStorage(window.pwconfig.firebase_config);

  return fileStorage;
}
