import useFigmaContext from '../../../../Figma/hooks/useFigmaContext';
import { IPrototypeRecordingOptions } from './useHtmlPrototypeRecording';

export default function useNativePrototypeRecording(props: IPrototypeRecordingOptions) {
	const recordingId = `${props.answerId}/${props.blockId}`;
	const figmaContext = useFigmaContext();

	function start() {
		figmaContext?.startRecording(recordingId);
	}

	async function finish() {
		return new Promise((resolve, reject) => {
			props.setIsUploading(true);
			
			const timeoutId = setTimeout(() => {
				props.setIsUploading(false);
				reject(new Error('video_upload_timeout'));
			}, 30 * 1000);

			figmaContext?.onRecordUploadComplete(() => {
				clearTimeout(timeoutId);
				props.setIsUploading(false);
				resolve(true);
			});

			figmaContext?.onRecordUploadFailed((recId: string, error: string) => {
				clearTimeout(timeoutId);
				props.setIsUploading(false);
				reject(error);
			});

			figmaContext?.stopRecording();
		});
	}

	function recordEvent() {

	}

	return {
		start,
		finish,
		recordEvent
	}
}