import { PermissionType } from "./types";

export default class BlockPermission implements Record<PermissionType, boolean> {

  private permissions: { [key in PermissionType]: boolean } = {
    [PermissionType.AUDIO]: false,
    [PermissionType.CAMERA]: false,
    [PermissionType.SCREEN]: false,
  };

  public get camera(): boolean {
    return this.permissions[PermissionType.CAMERA];
  }
  public set camera(value: boolean) {
    this.permissions[PermissionType.CAMERA] = value;
  }

  public get microphone(): boolean {
    return this.permissions[PermissionType.AUDIO];
  }
  public set microphone(value: boolean) {
    this.permissions[PermissionType.AUDIO] = value;
  }
  
  public get screen(): boolean {
    return this.permissions[PermissionType.SCREEN];
  }
  public set screen(value: boolean) {
    this.permissions[PermissionType.SCREEN] = value;
  }

  constructor(camera?: boolean, microphone?: boolean, screen?: boolean) {
    this.camera = camera ?? false;
    this.microphone = microphone ?? false; 
    this.screen = screen ?? false;
  }

  public clone(): BlockPermission {
    return new BlockPermission(this.camera, this.microphone, this.screen);
  }
}