/**
 * RecordResult is a class that contains the result of a recording for each stream.
 */
export class RecordResult {

  public canvasErrorReason: string | null = null;
  public audioErrorReason: string | null = null;
  public cameraErrorReason: string | null = null;
  public screenErrorReason: string | null = null;

  constructor(
    /**
     * Indicates if the canvas recording was successful.
     */
    public canvasRecord: boolean = false,
    
    /**
     * Indicates if the respondents audio recording was successful.
     */
    public audioRecord: RecordTiming | null = null,
    /**
     * Indicates if the respondents camera recording was successful.
     */
    public cameraRecord: RecordTiming | null = null,

    /**
     * Indicates if the screen recording was successful.
     */
    public screenRecord: RecordTiming | null = null
  ) { }
}

export class RecordTiming {
  constructor(
    public start: number = 0,
    public stop: number = 0
  ) { }
}
