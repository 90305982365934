import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { locale, interfaceText } from "../../../../../helpers";
import { pushToGTM } from "../../../../../utils/gtm";
import { useSendNotification } from "../../../../../hooks";
import { IconSuccess, IconPathway } from "../../../../../icons";
import Button from "../../../../Button";


export default function FinalStep(props: { verificationCode?: string; testId: string; redirectUrl: string; showAboutUsBlock: boolean | undefined }) {
  useEffect(() => {
    pushToGTM({
      event: "thankYouScreen",
      testId: props.testId,
    });
  }, []);

  return (
    <div className="container mx-auto h-full flex flex-col justify-between items-center">
      {/* <div className='absolute bg-blue-600 rounded-full -top-[80%] w-full h-full blur-[200px] opacity-[50%]'></div> */}
      {/* <div className='absolute bg-indigo-600 rounded-full -top-[50%] w-full h-[50%] blur-[120px] opacity-[75%]'></div> */}
      <div className="text-center flex flex-col items-center flex-1 h-full w-full justify-center" id="successMessage">
        <div className="flex flex-col items-center gap-4">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.25, delay: 0.25 }}
        >
          <IconSuccess className="w-[32px] h-[32px] fill-current text-green-500" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.25, delay: 0.5 }}
          className="text-lg font-medium"
        >
          {(interfaceText as any).thankYou[locale()]}
        </motion.div>
        </div>
        {props.verificationCode && <CodeBlock verificationCode={props.verificationCode} />}
        {props.redirectUrl && (
          <div className="mt-6 flex justify-center w-full">
            <a
              href={props.redirectUrl}
              rel="nofollow noreferrer"
              data-testid="test.thankYouButton"
              className="object-center block rounded-full transition-all font-medium duration-75 ease-in cursor-pointer tracking-tight bg-blue-600 text-white hover:bg-blue-700 py-1 text-xl px-4"
            >
              {(interfaceText as any).thankYouButton[locale()]}
            </a>
          </div>
        )}
      </div>
      {props.showAboutUsBlock && (
        <div className="p-8">
          <div className="border-1 border-gray-300 p-4 rounded-lg font-medium flex flex-col items-start justify-center gap-4 max-w-[520px]">
            <div className="flex items-start gap-3">
              <IconPathway className="w-6 h-6 shrink-0" />
              <div className="flex flex-col gap-2">
                <p className="text-gray-700">{(interfaceText as any).test[locale()].aboutPathway}</p>
                <a href={window.pwconfig.urls.landing_page} target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:opacity-50 transition-all duration-75 ease-in">{(interfaceText as any).test[locale()].tryPathway}</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export function CodeBlock(props: { verificationCode: string; }) {
  const sendNotification = useSendNotification();
  const { i18n } = useTranslation();

  const t = i18n.getFixedT(locale());

  return (
    <div className="verification-code flex items-center flex-col mt-6 max-w-lg">
      <div className="verification-code__title">
        {t("Copy the confirmation code and paste it into the corresponding task field to receive your reward.")}
      </div>
      <input
        className="mt-5 p-4 text-lg rounded-md border-gray-300 border-2 text-center"
        value={props.verificationCode}
        readOnly />
      <Button
        type="primary"
        name={t("Copy code")}
        className="whitespace-nowrap mt-5 flex-row"
        handler={() => {
          navigator.clipboard.writeText(props.verificationCode);
          sendNotification("info", t("Code copied"));
        } } />
    </div>
  );
}