// DEPRECATED: This file will be removed in future releases. Please avoid using functions from this file.

import { db, getAuthToken, Timestamp } from "./firebase";
import _ from "lodash";
import uniqid from "uniqid";
import { generateVerificationCode, generateId } from "./utils";
import { getBlockLogic } from "./utils/tests";
import { makeAuthorizedRequest } from "./http/Client";
import { ROOT_FOLDER_ID } from './Common/constants';
import i18n from "./i18n/config";

export const updateCredits = (value) => {
  return {
    type: "UPDATE_CREDITS",
    payload: {
      value: value,
    },
  };
};

export const getUserData = async (user) => {
  const authData = {
    userId: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
    detectedLanguage: i18n.language,
  };

  const userDataResponse = await makeAuthorizedRequest("/api/user/getUserData", "POST", authData);

  if (userDataResponse.ok) {
    return {
      type: "GET_USER_DATA",
      payload: await userDataResponse.json(),
    };
  }
  throw new Error("http_error", { cause: userDataResponse });
};

export const fetchTest = async (testId, sharingToken) => {
  const URL = sharingToken ? `/api/tests/${testId}?sharingToken=${sharingToken}` : `/api/tests/${testId}`;

  return await fetch(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getTests = async (folderId) => {
  const testsResponse = await makeAuthorizedRequest("/api/user/getTests", "POST", { folderId });

  if (testsResponse.ok) {
    return {
      type: "GET_TESTS",
      payload: {
        tests: await testsResponse.json(),
      },
    };
  }

  throw new Error("http_error", { cause: testsResponse });
};

// todo: this is not an action
export const createAnswer = async (testId, metadata, urlParams, respondentParams) => {
  return await fetch(`/api/tests/${testId}/answers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...respondentParams, meta: metadata, urlParams }),
  });
};

const getInitialTestData = (userId, folderId, projectId) => {
  return {
    answers: {},
    name: i18n.t("New test"),
    status: "draft",
    userId: userId,
    createdAt: Timestamp.now(),
    verificationCode: generateVerificationCode(),
    sharingToken: uniqid(),
    content: [],
    contentPreview: [],
    backgroundColor: "#FFFFFF",
    buttonsColor: "#0163E1",
    buttonsTextColor: "#FFFFFF",
    folderId: folderId,
    answersCounter: 0,
    ownerId: projectId || userId,
    ...(projectId ? { projectId: projectId } : {}),
  };
};

export const createTest = async (userId, folderId, projectId) => {
  const testInitialData = getInitialTestData(userId, folderId, projectId);

  const testDoc = await db.collection("tests").add(testInitialData);

  return {
    type: "CREATE_TEST",
    payload: {
      data: { [testDoc.id]: testInitialData },
    },
  };
};

export const updateTest = async (data, testId) => {
  const authToken = await getAuthToken();
  const URL = "/api/user/updateTest";

  try {
    await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Barrier ${authToken}`,
      },
      body: JSON.stringify({ data: data, testId: testId }),
    });
  } catch (error) {
    console.error(error);
    throw error;
  }

  return {
    type: "UPDATE_TEST",
    payload: {
      data: data,
      testId: testId,
    },
  };
};

export const updateTestSettings = async (key, value, testId) => {
  const authToken = await getAuthToken();
  const URL = "/api/user/updateTest";

  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Barrier ${authToken}`,
      },
      body: JSON.stringify({
        data: { [`settings.${key}`]: value },
        testId: testId,
      }),
    });

    if (!response.ok) {
      throw response;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }

  return {
    type: "UPDATE_TEST_SETTINGS",
    payload: {
      key: key,
      value: value,
      testId: testId,
    },
  };
};

export const sendAnswer = async (data, answerId, blockId, testId) => {
  return await fetch(`/api/tests/${testId}/answers/${answerId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data, blockId }),
  });
};
// todo: this is not an action
export const aproveAnswer = async (answerId, testId, isAnswersLimitExceeded, studyId) => {
  return await fetch(`/api/tests/${testId}/answers/${answerId}/approve`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ isAnswersLimitExceeded: !!isAnswersLimitExceeded, studyId }),
  });
};

export const deleteAnswer = async (answerId, testId) => {
  await fetch(`/api/tests/${testId}/answers/${answerId}/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return {
    type: "DELETE_ANSWER",
    payload: {
      testId: testId,
      answerId: answerId,
    },
  };
};

export const setUserData = async (userId, data) => {

  console.log(data);

  await db.collection("users")
    .doc(userId)
    .update({
      ...data,
    });

  return {
    type: "SET_USER_DATA",
    payload: {
      userId: userId,
      data: data,
    },
  };
};

export const setNotification = (type, text) => {
  return {
    type: "SET_NOTIFICATION",
    payload: {
      type: type,
      text: text,
    },
  };
};

export const setUi = (key, value) => {
  return {
    type: "SET_UI",
    payload: {
      key: key,
      value: value,
    },
  };
};

export const fetchPrototype = async (code) =>
  await fetch(`/api/prototypes/${code}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

// Teams actions

export const getTeam = async (projectId) => {
  const authToken = await getAuthToken();
  const URL = `/api/teams/getTeam`;

  const testsResponse = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Barrier ${authToken}`,
    },
    body: JSON.stringify({ projectId: projectId }),
  });

  if (testsResponse.ok) {
    return {
      type: "GET_TEAM",
      payload: {
        team: await testsResponse.json(),
      },
    };
  }

  return undefined;
};

export const getInvite = async (inviteCode) => {
  let inviteResponse;
  let response;

  try {
    inviteResponse = await makeAuthorizedRequest(`/api/invites/${inviteCode}`, "GET");
  } catch (error) {
    console.error(error);
    throw "NETWORK_ERROR";
  }

  if (inviteResponse.ok) {
    return (await inviteResponse.json()).invite;
  }

  try {
    response = await inviteResponse.json();
  } catch (error) {
    throw "SERVER_ERROR";
  }

  throw response.error_code ?? response.code;
};

export const acceptInvite = async (inviteCode) => {
  let inviteResponse;
  let response;

  try {
    inviteResponse = await makeAuthorizedRequest(`/api/invites/${inviteCode}/accept`, "POST");
  } catch (error) {
    console.error(error);
    throw "NETWORK_ERROR";
  }

  if (!inviteResponse.ok) {
    try {
      response = await inviteResponse.json();
    } catch (error) {
      throw "SERVER_ERROR";
    }

    throw response.code;
  }
};

export const createInvite = async (projectId) => {
  let response;
  let message;

  try {
    response = await makeAuthorizedRequest("/api/invites", 'POST', { projectId });
  } catch (error) {
    throw "NETWORK_ERROR";
  }

  try {
    message = await response.json();
  } catch (error) {
    throw "SERVER_ERROR";
  }

  if (!response.ok) {
    throw message.code;
  }

  return `${window.location.protocol}//${window.location.host}/invite/${message.invite.code}`;
  // return process.env.NODE_ENV === "development"
  //   ? 
  //   : `https://app.pthwy.design/invite/${message.invite.code}`;
};
export const updateRole = async (projectId, memberId, role) => {
  const response = await makeAuthorizedRequest(`/api/teams/updateTeamMemberRole`, "POST", { projectId, memberId, role });

  if (response.ok) {
    return {
      type: "UPDATE_ROLE",
      payload: {
        memberId: memberId,
        role: role,
      },
    };
  }

  return undefined;
};

export const removeMember = async (projectId, memberId) => {
  const response = await makeAuthorizedRequest(`/api/teams/removeTeamMember`, "POST", { projectId, memberId });

  if (response.ok) {
    return {
      type: "REMOVE_MEMBER",
      payload: {
        memberId: memberId,
      },
    };
  }

  return undefined;
};

export const updateTeam = async (projectId, field, value) => {
  const testsResponse = await makeAuthorizedRequest(`/api/teams/updateTeam`, "POST", {
    projectId,
    field,
    value
  });

  if (testsResponse.ok) {
    return {
      type: "UPDATE_TEAM",
      payload: {
        field: field,
        value: value,
      },
    };
  }

  return undefined;
};
