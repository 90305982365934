import React from "react";
import "../../../../../../tailwind.generated.css";

import Modal from "../../../../../Modal";
import Button from "../../../../../Button";
import { IconSuccess } from "../../../../../../icons";

import { locale, interfaceText } from "../../../../../../helpers";

import TestBranding from "../TestBranding";
import { usePermissionsContext } from "./context/PermissionsContext";
import { PermissionState } from "./models/types";

function FigmaSuccessModal(props: {
  onClick: (e: React.MouseEvent | undefined) => void;
  isOpen: boolean;
  isLoading?: boolean;
}) {
  const { permissions } = usePermissionsContext();

  return (
    <Modal
      isOpen={props.isOpen}
      setIsOpen={() => { }}
      width="480px"
      disallowClickOutside
      sidebarMode
    >
      <div className="success-modal mx-auto flex flex-col gap-3 justify-between h-full">
        <div className="flex flex-col gap-6 p-5 h-full">
          <TestBranding />
          <div className="flex flex-col gap-4 h-full justify-center items-center">
            <IconSuccess className="w-[28px] h-[28px] fill-current text-green-500" />
            <div className="flex items-center text-lg font-medium gap-2">
              {(interfaceText as any).figmaTestingSuccess[locale()].header}
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center pb-5 px-5 gap-4 items-center">
          {(permissions.camera === PermissionState.GRANTED || permissions.microphone === PermissionState.GRANTED || permissions.screen === PermissionState.GRANTED) && <p className="text-xxs text-gray-500">{interfaceText.figmaTestingSuccess[locale()].loadingText}</p>}
          <Button
            className="success-modal__button"
            name={props.isLoading ? (interfaceText.figmaTestingMission as any)[locale()].loading : (interfaceText as any).figmaTestingSuccess[locale()].button}
            handler={props.onClick}
            large
            type="primary"
            disabled={props.isLoading}
            isLoading={props.isLoading}
            fullWidth
          />
        </div>
      </div>
    </Modal>
  );
}

export default FigmaSuccessModal;
