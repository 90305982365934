import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import _ from "lodash";
import "../../../../../tailwind.generated.css";
import clsx from "clsx";

import { PreferenceBlock, Block } from "../../../../../models/Test";
import PreferenceSelect from "./PreferenceSelect";

import useIsMobile from "../hooks/useIsMobile";

import { locale, interfaceText } from "../../../../../helpers";

import TestBranding from "./TestBranding";
import Loader from "../../../../Loader";
import { IconImage } from "../../../../../icons";
import NumberFlow from '@number-flow/react';

type TaskProps = {
  blockData: PreferenceBlock & Block;
  caption: React.ReactNode | string;
  // instructionText: string;
};

function Task(props: TaskProps) {
  return (
    <div>
      <div className="text-lg font-medium flex-shrink-0 break-words flex flex-col w-full gap-1">
        <div>{parse(props.blockData.questionHtml || props.blockData.text)}</div>
        <div className="text-gray-700 text-sm font-normal mt-1">
          {props.caption}
        </div>
      </div>
    </div>
  );
}

interface IPreferenceReplyOption {
  id: string;
  replyValue: string;
  image: string;
}

class ComparisonPair {
  constructor(
    public id: string,
    public options: IPreferenceReplyOption[]
  ) { }
}

function generateComparisonPairs(options: IPreferenceReplyOption[]): ComparisonPair[] {
  const pairs = [];
  for (let i = 0; i < options.length; i++) {
    for (let j = i + 1; j < options.length; j++) {
      pairs.push(new ComparisonPair(`${options[i].id}-${options[j].id}`, [options[i], options[j]]));
    }
  }
  return pairs;
};

interface IPreferenceProps {
  blockData: PreferenceBlock & Block;
  blockAnswer: any;
  handleBlockAnswer: any;
  withProgress?: boolean;
  responseStart?: any;
  setResponseStart?: any;
  handleSubmitBlockAnswer: (additionalBlockAnswerData?: Object) => void;
}

interface IInstructionsProps {
  currentPairIndex?: number;
  totalPairs?: number;
}

function PairwiseInstructions({ currentPairIndex, totalPairs }: IInstructionsProps) {
  return (
    <div className="flex gap-2 items-start">
      <IconImage className="w-4 h-4 fill-current text-gray-800 shrink-0 mt-[2px]" />
      <span className="text-gray-700 text-sm">
        {interfaceText.showingComparison[locale()]}
        <span className="font-medium text-gray-800">
          {" "}<NumberFlow value={currentPairIndex! + 1} /> {interfaceText.of[locale()]} {totalPairs}
        </span>.
        {" "}{interfaceText.makeSureToViewBothImagesBeforeChoosingOne[locale()]}
      </span>
    </div>
  );
}

function SingleInstructions() {
  return (
    <div className="flex flex-col gap-2">
      <span className="text-gray-700 text-sm">
        {interfaceText.makeSureToViewAllImagesBeforeChoosingOne[locale()]}
      </span>
    </div>
  );
}

export default function Preference(props: IPreferenceProps) {
  const isMobile = useIsMobile();
  const [comparisonPairs, setComparisonPairs] = useState<ComparisonPair[]>([]);
  const [currentPairIndex, setCurrentPairIndex] = useState(0);
  const [responses, setResponses] = useState<{ pairId: string; option1: string, option2: string, winner: string }[]>([]);

  const isPairwise = props.blockData.comparisonType === "pairwise";

  useEffect(() => {
    if (props.blockData.comparisonType === "pairwise") {
      const pairs = generateComparisonPairs(props.blockData.replies);
      setComparisonPairs(pairs);
    }
  }, [props.blockData]);

  useEffect(() => {
    props.handleBlockAnswer('selectedOptions', responses);
  }, [responses]);

  // Срабатывает, когда выбрали картинку и после этого она показывается на весь экран
  function handleSelection(selectedOption: Omit<IPreferenceReplyOption, "image">) {
    console.log("selectedOption", selectedOption);
    if (props.blockData.comparisonType === "pairwise") {
      const currentPair = comparisonPairs[currentPairIndex];
      const newResponse = {
        pairId: currentPair.id,
        option1: currentPair.options[0].id,
        option2: currentPair.options[1].id,
        winner: selectedOption.id,
      };

      const updatedResponses = [...responses.filter(r => r.pairId !== currentPair.id), newResponse];
      setResponses(updatedResponses);
    } else {
      props.handleBlockAnswer('selectedOptions', [selectedOption]);
    }
  }

  // Срабатывает, когда картинка уже показывается на весь экран и нажали Выбрать
  function onSubmit() {
    if (props.blockData.comparisonType === "pairwise") {
      // Для попарного сравнения берем следующую пару, если есть
      if (currentPairIndex < comparisonPairs.length - 1) {
        setCurrentPairIndex(currentPairIndex + 1);
      } else {
        props.handleSubmitBlockAnswer();
      }
    }
    else {
      // Для обычного сравнения сохраняем сразу уже выбранную картинку
      props.handleSubmitBlockAnswer();
    }
  }

  if (isPairwise && !comparisonPairs.length) {
    return <Loader />;
  }

  return (
    <>
      <div className={clsx("h-full flex flex-col gap-6 flex-1 overflow-y-auto overflow-x-hidden", isMobile ? "p-4" : "p-8")}>
        <TestBranding />
        <Task blockData={props.blockData} caption={isPairwise ? <PairwiseInstructions currentPairIndex={currentPairIndex} totalPairs={comparisonPairs.length} /> : <SingleInstructions />} />
        <PreferenceSelect
          type={props.blockData.comparisonType}
          data={isPairwise ? comparisonPairs[currentPairIndex].options : props.blockData.replies}
          isRandomized={props.blockData.randomized}
          onOptionSubmitted={onSubmit}
          onOptionSelected={handleSelection}
        />
      </div>
    </>
  );
}
