import React from "react";
import { IconCamera, IconMic } from "../../../../../../icons";
import { interfaceText, locale } from "../../../../../../helpers";
import { PermissionType } from "./models/types";

interface PermissionViewProps {
  type: PermissionType | [PermissionType.AUDIO, PermissionType.CAMERA];
}

export function PermissionView(props: PermissionViewProps) {
  const renderIcon = (isActive: boolean) => (
    <div className={`permission-view__icon flex flex-col items-center p-2 rounded-lg gap-2 ${isActive ? 'border-3 border-blue-300' : ''}`}>
      <div className={`permission-view__icon-image w-16 h-[55px] rounded-3 ${isActive ? 'bg-blue-300' : 'bg-gray-300'} rounded-lg`}></div>
      <div className={`permission-view__icon-bar w-full h-1 ${isActive ? 'bg-blue-300' : 'bg-gray-300'} rounded-full`} />
    </div>
  );

  const renderButton = (text: string) => (
    <div className="permission-view__actions flex items-center justify-end gap-2">
      <div className="permission-view__action border-1 border-gray-300 p-3 rounded-md">
        <div className="permission-view__action-bar w-12 h-[6px] bg-gray-300 rounded-full" />
      </div>
      <div className="permission-view__action border-1 border-gray-300 px-3 py-1 rounded-md">
        <p className="permission-view__action-text text-gray-700">{text}</p>
      </div>
    </div>
  );

  if (props.type === PermissionType.SCREEN) {
    return (
      <div className="permission-view w-full h-[220px] rounded-md bg-[#03604A] relative flex items-center justify-center">
        <div className="permission-view__content absolute rounded-md bg-white z-10 p-3 gap-2 flex flex-col">
          <div className="permission-view__icons flex justify-between">
            {renderIcon(true)}
            {renderIcon(false)}
            {renderIcon(false)}
          </div>

          {renderButton(interfaceText.figmaTestingMission[locale()].share)}
        </div>
      </div>
    );
  }

  return (
    <div className="permission-view w-full h-[220px] rounded-lg bg-[#C4B9FA] relative flex items-center justify-center">
      <div className="permission-view__content absolute rounded-lg bg-white z-10 p-4 gap-2 flex flex-col">
        <div className="permission-view__icons flex gap-3">
          {props.type === PermissionType.AUDIO ? <IconMic width={20} height={20} /> : <IconCamera stroke="#1F2226" width={20} height={20} />}

          <div className="flex flex-col justify-start gap-2">
            <div className="w-[80%] mr-4 h-3 rounded-full bg-gray-300" />
            <div className="w-[50%] h-3 rounded-full bg-gray-300 mb-2" />
            {renderButton(interfaceText.figmaTestingMission[locale()].allow)}
          </div>
        </div>
      </div>
    </div>
  )
}
