import { useState, useEffect } from 'react';

export const FACE_RECORDING_CONTAINER_SIZE = 116;
export const PREVIEW_LABEL_HEIGHT = 40;
export const PADDING = 30;

export default function useDraggable(isPreview: boolean) {
  const initialPosition = {
    x: window.innerWidth - FACE_RECORDING_CONTAINER_SIZE - PADDING,
    y: window.innerHeight - FACE_RECORDING_CONTAINER_SIZE - (isPreview ? PREVIEW_LABEL_HEIGHT : 0) - PADDING,
  };

  const [position, setPosition] = useState(initialPosition);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  function handleMouseDown(e: React.MouseEvent) {
    setIsDragging(true);
    setDragOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  function handleMouseMove(e: MouseEvent) {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragOffset.x,
        y: e.clientY - dragOffset.y,
      });
    }
  };

  function handleMouseUp() {
    setIsDragging(false);
    setPosition((prevPosition) => ({
      x: Math.max(PADDING, Math.min(prevPosition.x, window.innerWidth - FACE_RECORDING_CONTAINER_SIZE - PADDING)),
      y: Math.max(PADDING, Math.min(prevPosition.y, window.innerHeight - FACE_RECORDING_CONTAINER_SIZE - (isPreview ? PREVIEW_LABEL_HEIGHT : 0) - PADDING)),
    }));
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  useEffect(() => {
    const offsetX = window.innerWidth - position.x - FACE_RECORDING_CONTAINER_SIZE - PADDING;
    const offsetY = window.innerHeight - position.y - FACE_RECORDING_CONTAINER_SIZE - (isPreview ? PREVIEW_LABEL_HEIGHT : 0) - PADDING;

    function handleResize() {
      setPosition({
        x: window.innerWidth - FACE_RECORDING_CONTAINER_SIZE - PADDING - offsetX,
        y: window.innerHeight - FACE_RECORDING_CONTAINER_SIZE - (isPreview ? PREVIEW_LABEL_HEIGHT : 0) - PADDING - offsetY,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [position, isPreview]);

  return {
    isDragging,
    position,
    handleMouseDown,
  };
};