import { MIME_TYPES } from '../../../../../../../Common/constants';

export class VideoRecorder {
  public recorder: MediaRecorder | null = null;
  private chunks: Blob[] = [];
  public videoURL: string | null = null;
  public result: string | null = null;
  public startTS: number = 0;
  public stopTS: number = 0;
  public stopped: boolean = false;

  constructor(
    public stream: MediaStream,
    public fps: number = 15,
    mediaRecorderOptions: MediaRecorderOptions = { mimeType: MIME_TYPES.VIDEO_MP4 },
    private onRecordStart?: (timestamp: number) => void,
    private onRecordStop?: (timestamp: number) => void,
  ) {
    this.initRecorder(mediaRecorderOptions);
  }

  initRecorder(mediaRecorderOptions: MediaRecorderOptions) {
    this.recorder = new MediaRecorder(this.stream, mediaRecorderOptions);
    this.recorder.ondataavailable = this.onRecorderDataAvailable.bind(this);
    this.recorder.onstop = this.onRecorderStop.bind(this);
    this.recorder.onstart = () => {
      this.startTS = performance.now();
      this.onRecordStart?.(this.startTS);
    };
  }

  start() {
    this.stopped = false;
    this.startTS = 0;
    this.stopTS = 0;
    this.recorder?.start();
  }

  stop() {
    if (this.recorder?.state !== 'inactive') {
      this.recorder?.stop();
    }
  }

  pause() {
    this.recorder?.pause();
  }

  resume() {
    this.recorder?.resume();
  }

  private onRecorderDataAvailable(e: BlobEvent) {
    this.chunks.push(e.data);
  }

  private onRecorderStop(e: Event) {
    this.stopped = true;
    this.stopTS = performance.now();
    this.onRecordStop?.(this.stopTS);
  }

  public getVideoBlob(type?: string) {
    // check if recorder is stopped in interval of 100ms and return video blob if it is stopped
    const getVideoPromise = new Promise<Blob>((resolve, reject) => {
      let retryCount = 0;
      const interval = setInterval(() => {
        retryCount++;
        if (this.stopped) {
          clearInterval(interval);
          var blob = new Blob(this.chunks, { type: type || 'video/webm' });
          this.chunks = [];
          resolve(blob);
        }
        if (retryCount > 50) {
          clearInterval(interval);
          reject('VideoRecorder: getVideoBlob failed');
        }
      }, 100);
    });
    return getVideoPromise;
  }

  public getVideoURL() {
    // check if recorder is stopped in interval of 100ms and return video url if it is stopped
    const getVideoPromise = new Promise((resolve, reject) => {
      let retryCount = 0;
      const interval = setInterval(() => {
        retryCount++;
        if (this.stopped) {
          clearInterval(interval);
          var blob = new Blob(this.chunks, { type: 'video/webm' });
          this.chunks = [];
          this.videoURL = URL.createObjectURL(blob);

          var myReader = new FileReader();
          myReader.readAsDataURL(blob);
          myReader.onerror = reject;
          myReader.addEventListener("loadend", (e: any) => {
            this.result = e.srcElement.result;
            console.log("mp4 video data url:", e.srcElement.result, this.videoURL);
            resolve(e.srcElement.result);
          });
        }
        if (retryCount > 50) {
          clearInterval(interval);
          reject('VideoRecorder: getVideoURL failed');
        }
      }, 100);
    });
    return getVideoPromise;
  }
}
