import { useRef } from "react";
import { listenerHandler } from "@rrweb/types";
import * as rrweb from "rrweb";

import useFileStorage from "../../../../../Common/storage/useFileStorage";


const RECORDING_UPLOAD_INTERVAL = 10 * 1000 // 5 sec

export interface IPrototypeRecordingOptions {
  blockId: string;
  answerId: string; 
  setIsUploading: (value: boolean) => void;
}

export default function useHtmlPrototypeRecording({ blockId, answerId, setIsUploading }: IPrototypeRecordingOptions) {
  // const [events, setEvents] = useState<any[]>([]);
  const fileStorage = useFileStorage();

  const stopRecording = useRef<listenerHandler | undefined>();
  const recordingTimeout = useRef<NodeJS.Timeout | undefined>();
  const recordedEvents = useRef<any[]>([]);


  function start() {
    stopRecording.current = rrweb.record({
      emit(event) {
        recordedEvents.current.push(event);
      },
    });
    // recordingTimeout.current = setTimeout(sendEventsAndClear, RECORDING_UPLOAD_INTERVAL);
  }

  async function finish() {
    if (stopRecording.current) {
      stopRecording.current();
    }
    // clearTimeout(recordingTimeout.current);

    // TODO: The best practice is to make such a request 
    // at the time the answer is saved
    // sendRecordingData(events);

    if (recordedEvents.current?.length) {
      try {
        setIsUploading(true);
        const task = await fileStorage.uploadString(JSON.stringify(recordedEvents.current), `recordings/${answerId}-${blockId}.json`, 'raw');

        setIsUploading(false);
      } catch (error) {
        console.error('Error while saving video recording', `${answerId}-${blockId}`, error)
      }
    }
  }

  // async function sendEventsAndClear() {
  //   const currentEvents = [...events];
  //   const result = await sendRecordingData(currentEvents);
  //   // if (result.status === 201) {
  //   //   setEvents((current) => {
  //   //     current.splice(0, currentEvents.length);
  //   //     return current;
  //   //   });
  //   // }

  //   recordingTimeout.current = setTimeout(sendEventsAndClear, RECORDING_UPLOAD_INTERVAL);
  // }

  async function sendRecordingData(data: any[]) {
    // recordedEvents.current += data.map(e => JSON.stringify(e) + '\n').join('');
    // const task = await uploadString(fileRef, , 'raw');
    return true;
    // return await fetch(`/api/storage/recording`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     newData: data,
    //     blockId: blockId,
    //     answerId: answerId,
    //     plan: plan,
    //   }),
    // });
  }


  function recordEvent(eventName: string, payload: Record<string, string>) {
    rrweb.record.addCustomEvent(eventName, payload);
  }

  return { start, finish, recordEvent };
};
